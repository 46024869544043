@use "../common/bootstrap.scss" as b;

.headshot {
    max-width: 100%;
    width: 100%;
    max-height: 100%;
    height: 100%;
    aspect-ratio: 1 / 1;

    border-radius: 50%;

    object-fit: cover;
}


.headshot-container {
    max-width: 100%;
    width: 100%;
    min-width: 100%;
    aspect-ratio: 1 / 1;

    position: relative;
}

.outline {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    max-width: 100%;
    width: 100%;
    max-height: 100%;
    height:100%;
    aspect-ratio: 1 / 1;

    border-radius: 50%;
    border-width: 15px;
    border-style: solid;
    border-color: rgb(205, 205, 205);
    transition: border-color .25s ease, border-width .25s ease;
}

.outline:hover {
    border-width: 20px;
    border-style: solid;
    border-color: b.$primary;
    transition: border-color .25s ease, border-width .25s ease;
}