@import url('https://fonts.googleapis.com/css2?family=Arimo&family=Karla:wght@700&display=swap');
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.9.1/font/bootstrap-icons.css");

body {
    font-family: Arimo, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
h1, h2, h3, h4, h5, h6 {
    font-family: Karla, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.tracked {
    text-transform: uppercase;
    letter-spacing: .25em;
}

.full {
    min-height: 100vh;
    min-width: 100%;
    max-height: 100vh;
    max-width: 100%;
    height: 100vh;
    width: 100%;
}
  
.center {
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    flex-direction:column;
}

.pointer {
    cursor: pointer;
}