@use "../common/bootstrap.scss" as b;
$header-transition-speed: .5s;

.header {
    z-index: 2;
    position: fixed;
    top: 0;
    width: 100%;
    background: white;
    transition: background $header-transition-speed ease-out;
}

.transparent {
    background: transparent;
    transition: background $header-transition-speed ease-out;
}

.transition-text-color {
    transition: color $header-transition-speed ease-out;
}

.transition-text-color:hover {
    color: b.$primary !important;
}

.mobile-menu {
    position: fixed;
    top: 0;
    background: black;
    z-index: 998;
}

.mobile-links {
    position:fixed;
    top:0;
    height: 100%;
    max-height:100%;
    z-index:998;
}

.mobile-menu-top {
    position:fixed;
    top:0;
    z-index: 999;
    width: 100%;
}