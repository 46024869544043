@use "../common/bootstrap.scss" as b;

.load {
    text-align: center;
}

.load > div {
    margin: 1em;
    width: 2em;
    height: 2em;
    background-color: b.$primary;

    border-radius: 100%;
    display: inline-block;
    -webkit-animation: delay 1.75s infinite ease-in-out both;
    animation: delay 1.75s infinite ease-in-out both;
}

.load .circle2 {
    -webkit-animation-delay: 0.25s;
    animation-delay: 0.25s;
}

.load .circle3 {
    -webkit-animation-delay: 0.5s;
    animation-delay: 0.5s;
}
.load .circle4 {
    -webkit-animation-delay: 0.75s;
    animation-delay: 0.75s;
}
.load .circle5 {
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
}

@-webkit-keyframes delay {
    0%, 80%, 100% { -webkit-transform: scale(0) }
    40% { -webkit-transform: scale(1.0) }
}

@keyframes delay {
    0%, 80%, 100% { 
        -webkit-transform: scale(0);
        transform: scale(0);
    } 40% { 
        -webkit-transform: scale(1.0);
        transform: scale(1.0);
    }
}