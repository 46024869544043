@use "../common/bootstrap.scss" as b;

.text-input {
    padding: .75rem 1.25rem;
    border: 2px solid rgb(65, 65, 65);

    border-radius: 4px;
    background-color: transparent;
    margin-bottom: 1rem;
    width: min(35vw, 45rem);
    font-size: 1.6rem;

    transition: border-color .35s ease-in-out;
}

.text-input:focus {
    border-color: white;
}

.text-input:hover {
    border-color: rgb(99, 99, 99);
}

.white-text {
    color:white;
}

.submit-input {
    padding: .75rem 1.25rem;
    border: 2px solid rgb(65, 65, 65);

    border-radius: 4px;
    background-color: transparent;
    margin-bottom: 1rem;
    font-size: 1.4rem;

    transition: border-color .35s ease-in-out;
    transition: background-color .35s ease-in-out;

    cursor: pointer;
}

.submit-input:focus {
    border-color: white;
    background-color: b.$primary; 
}

.submit-input:hover {
    border: 2px solid transparent;
    background-color: b.$primary;  
}