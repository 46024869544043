.hero-container {
    min-height: 100vh;
    min-width: 100%;
    max-height: 100vh;
    max-width: 100%;
    overflow: hidden;
    background-color: black;
    position: relative;
}

.hero-video {
    width: 100%;
    height: 100vh;
    max-height: 100vh;
    max-width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    object-fit: cover;  
    overflow: hidden;
    background-color:black;
    will-change: filter;
}

.hero-image {
    opacity: .7;
    border: none;
    transition: opacity .25s ease-in-out;
}

.hero-image:hover {
    opacity: 1;
    transition: opacity .25s ease-in-out;
}

@media (max-width: 768px) {
    .hero-image {
        max-width: 75%;
    }
}

@media (min-width: 768px) {
    .hero-image {
        max-width: min(1024px, 35%);
    }
}