.card {
    width: 100%;
    border-radius: 15px;
    border: 1px solid #dddddd;
    overflow: hidden;
}

.card-thumbnail {
    width: 100%;
    position: relative;
    border: none;
}

.card-arrow-container {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.arrow {
    color: white !important;
    opacity: .5;
    transition: opacity .35s ease-in-out;
    cursor: pointer;
}

.arrow:hover {
    color: white;
    opacity: 1;
    transition: opacity .35s ease-in-out;
}