@use "../common/bootstrap.scss" as b;

.footer-icon {
    color: white;
    cursor: pointer;
    transition: color .25s ease-in-out;
    font-size: 3rem;
}

.footer-icon:hover {
    color: b.$primary;
    transition: color .25s ease-in-out;
}

.footer-action-text {
    color: white;
    cursor: pointer;
    text-transform: uppercase;
    transition: color .25s ease-in-out;
    letter-spacing: .35rem;

    -moz-user-select: none;
   -khtml-user-select: none;
   -webkit-user-select: none;
   -ms-user-select: none;
   user-select: none;
}

.footer-action-text:hover {
    color: b.$primary;
    transition: color .25s ease-in-out;
}